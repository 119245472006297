<template>
	<div class="body">
		<dl class="register">
			<dt>
				<h1>业主单位登记</h1>
				<p>注：提交的资料经审核通过后，『经办人』才可登录系统。如您的单位已登记，请联系单位负责人开通账号即可</p>
			</dt>
			<dd>
				<c-form ref="form" unit-width="450">
					<c-form-item type="input" label="单位名称" name="name" required></c-form-item>
					<c-form-item type="input" label="单位负责人" name="contacts" required></c-form-item>
					<c-form-item type="input" label="负责人电话" name="mobile" required word="接收短信通知"></c-form-item>
					<c-form-item type="input" label="经办人" name="jbr_xm" required word="使用该姓名登录"></c-form-item>
					<c-form-item type="input" label="经办人手机" name="jbr_mobile" required word="使用该手机号登录"></c-form-item>
					<c-form-item type="select" label="单位所属行业" name="industry" required options="INDUSTRY"></c-form-item>
					<c-form-item type="region" label="办公地址" province-name="province" city-name="city" county-name="district" address-name="address" required></c-form-item>
					<c-form-item type="input" label="领导审批手机" name="fgld_phone" required word="业主审批时使用该手机接收授权码"></c-form-item>
					<c-form-item type="input" label="印章验证手机" name="seal_phone" required word="业主在线签章时使用该手机接收签章授权码"></c-form-item>
					<c-form-item type="input" label="电子印章编号" name="sealsn" word="需要提前授权给平阳县交通运输局"></c-form-item>
					<c-form-item type="input" label="组织机构代码" name="yyzzh"></c-form-item>
					<c-form-item>
						<c-button size="m" @click="submit">确认提交</c-button>
					</c-form-item>
				</c-form>
			</dd>
		</dl>
		
		<div class="foot-box">
			<div class="foot flex-between-center">
				<p>
					<span>浙江银江智能信息技术有限公司</span>
					<span>提供技术支持</span>
				</p>
				
				<p>
					<span>平台客服邮箱：yjszbh@163.com</span>
					<span>浙ICP备2022015443号-1</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import fun from '@/assets/script/function.js'
	
	export default {
		methods: {
			submit() {
				this.$refs.form.submit({
					url: '/yzdw/add',
					rule: {
						name: '请填写单位名称',
						contacts: '请填写单位负责人',
						mobile: '请填写负责人电话',
						jbr_xm: '请填写经办人',
						jbr_mobile: '请填写经办人电话',
						industry: '请选择单位所属行业',
						district: '请选择办公地址',
						address: '请填写办公地址详细地址',
						fgld_phone: '请填写领导审批手机',
						seal_phone: '请填写印章验证手机'
					},
					success: () => {
						this.$confirm({
							text: '提交成功',
							message: '您的资料已经提交成功，请等待审核',
							buttonName: '我知道了',
							cancelButton: false,
							resolve: () => {
								this.navigateTo('/login');
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.body {
		position: relative;
		min-height: 100vh;
		padding-top: 145px;
		overflow: hidden;
		background-image: url('~@/assets/image/register_bg.png');
		background-repeat: no-repeat;
		background-position: left top;
		background-size: auto 145px;
	}
	
	.register {
		width: 1000px;
		margin: 48px auto 60px;
		background: #fff;
		box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
		
		dt {
			height: 70px;
			border-bottom: 1px solid #eee;
			padding: 0 25px;
			line-height: 80px;
			
			h1 {
				float: left;
				font-size: 22px;
			}
			
			p {
				float: right;
				font-size: 13px;
				color: #FF5555;
			}
		}
		
		dd {
			padding: 20px 0 30px 100px;
			
			>>> .c-button {
				width: 232px;
				height: 40px !important;
				margin-left: 105px;
				margin-top: 40px;
			}
		}
	}
	
	.foot {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 62px;
		padding: 0 46px;
		font-size: 13px;
		color: #1492FF;
		background: #002774;
		
		&-box {
			height: 62px;
		}
		
		span {
			display: inline-block;
			margin: 0 5px;
		}
	}
</style>